import React from 'react'
import {NavLink} from "react-router-dom";

export const Foother = () => {
    return (
        <footer id="" className="w-full flex bg-[#fff] drop-shadow justify-center xs:max-w-[358px] md:max-w-[920px] xl:max-w-[100vw] mx-auto   mt-[85px] md:mt-[100px] px-4 xs:px-0 ">
            <div className="w-full md:ml-[54px] flex flex-col md:max-w-[1176px] items-center text-[#6B7280]">
                <div className="w-full flex flex-wrap justify-between bt-solid md:pt-[27px]  ">
                    <div className="w-full xl:w-[400px]">
                            <NavLink className="block text-[#6B7280] mt-[14px] md:mt-[18px] text-sm md:text-base font-bold hover:text-indigo-500" to="exzample@dispatchwizard.co">
                                exzample@dispatchwizard.co
                            </NavLink>
                        <p className="mt-[14px] md:mt-[18px] text-[#6B7280] text-sm md:text-base hover:text-indigo-500 cursor-pointer">+1 (XXX) XXX-XXXX</p>
                    </div>
                    <div className="mt-[24px] flex justify-between  w-[220px]  xl:ml-[198px] ">
                        <ul className="flex text-sm md:text-base  flex-col gap-[14px] ">
                            <li className="hover:text-indigo-500">
                                <NavLink  to="/policy">Privacy policy</NavLink>
                            </li>
                            <li className="hover:text-indigo-500">
                                <NavLink  to="/term">Terms of Condition</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <p className="my-[36px] mb-[50px] w-max text-xs md:text-sm  md:mx-0 ">Copyright © 2023. Carrierify</p>
            </div>
        </footer>
    )
}
