import React from 'react'

export const Contact = () => {
    return (
        <section className='lg:max-w-[1200px] w-full mt-[135px]  '>
            <form className=' bg-white drop-shadow-2xl  text-[#999] flex flex-col m-5 items-center gap-3 rounded-xl p-5 min-h-[350px] w-[90vw] md:w-[560px]'>
                <div className='text-[26px] text-[#444] font-bold'>
                    We're thrilled to be of assistance!
                </div>
                <div className='max-w-[450px] text-sm lg:mx-[65px]'>
                    Kindly provide some information, and we will get in touch with you shortly.
                </div>
                <div className='flex justify-between gap-5 w-[80vw] md:w-[480px]'>
                    <input type="text" placeholder='First name' className='outline-none border border-solid w-[35vw] md:w-[200px] border-slate-400 rounded-md p-2 max-h-[45px] focus:border-green-400'/>
                    <input type="text" placeholder='Last name' className='outline-none border border-solid w-[35vw] md:w-[200px] border-slate-400 rounded-md p-2 max-h-[45px]  focus:border-green-400' />
                </div>
                <input type="email" placeholder='Email' className='outline-none border w-[80vw] md:w-[480px] border-solid border-slate-400 rounded-md p-2 max-h-[45px]  focus:border-green-400' />
                <input type="text" placeholder='Web site' className='outline-none border w-[80vw] md:w-[480px] border-solid border-slate-400 rounded-md p-2 max-h-[45px]  focus:border-green-400' />
                <input type="text" placeholder='Numbers of dispatchers' className='outline-none border w-[80vw] md:w-[480px] border-solid border-slate-400 rounded-md p-2 max-h-[45px]  focus:border-green-400' />
                <textarea  placeholder='How we can help?' className='outline-none  resize-none border w-[80vw] md:w-[480px] border-solid border-slate-400 rounded-md p-2 h-[150px]  focus:border-green-400' />
                <button className='w-[80vw] md:w-[480px] h-[45px] hover:bg-[80vw] md:hover:bg-[480px] bg-gradient-to-br bg-indigo-500 to-sky-400 text-lg justify-center rounded-xl text-white  focus:border-green-400'>
                    Submit
                </button>
            </form>
        </section>
    )
}
