import React, {useState} from 'react'
import {NavLink} from "react-router-dom";
import logo from '../../acces/logo.png'
export const Header = () => {
    const [Open,setOpen] = useState<boolean>(false)
    return (
        <div className='w-screen fixed z-[100] bg-[#fff] h-[120px] drop-shadow flex justify-center '>
            <header className='w-[80vw] xl:w-[1200px] h-[120px] flex mx-3 xl:mx-10 text-[#444] items-center text-[19px] font-semibold justify-evenly'>
                <NavLink to={'/'} className='flex gap-5 items-center hover:text-indigo-500'>
                    <img className='w-[65px] h-[65px] object-cover' src={logo} alt=""/>
                    <div>
                        MasterDispatch
                    </div>
                </NavLink>
                <NavLink to={'/#features'} className='hover:text-indigo-500 hidden lg:block'>
                    Features
                </NavLink>
                <NavLink to={'/#product'} className='hover:text-indigo-500 hidden lg:block'>
                    Product
                </NavLink>
                <NavLink to={'/#pricing'} className='hover:text-indigo-500 hidden lg:block'>
                    Pricing
                </NavLink>
                <NavLink to={'/contact'} className='hover:text-indigo-500 hidden lg:block'>
                    Contact us
                </NavLink>
                <button className='bg-gradient-to-br hover:bg-[160px]  hidden lg:block from-indigo-500  to-sky-400 text-white font-bold text-[17px] w-[160px] h-[45px] rounded'>
                    <a href='/'>
                        Start Free
                    </a>
                </button>
                <div className='block lg:hidden'>
                    <div onClick={()=>setOpen(!Open)} className="p-4 space-y-2 bg-indigo-500 rounded shadow">
                        {
                            Open ?<svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="w-6 h-6">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>:<svg className="w-6 h-6" fill="#fff" viewBox="0 0 24 24" stroke="#fff">
                            <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                            />
                            </svg>
                        }
                    </div>
                    {
                        Open && <div className='w-screen bg-white absolute left-0 top-[120px] flex flex-col gap-4 px-10 py-6'>
                            <NavLink to={'/#features'} className='hover:text-indigo-500 '>
                            Features
                            </NavLink>
                            <NavLink to={'/#product'} className='hover:text-indigo-500 '>
                                Product
                            </NavLink>
                            <NavLink to={'/#pricing'} className='hover:text-indigo-500 '>
                                Pricing
                            </NavLink>
                            <NavLink to={'/contact'} className='hover:text-indigo-500 '>
                                Contact us
                            </NavLink>
                            <button className='bg-gradient-to-br hover:bg-[160px] from-indigo-500  to-sky-400 text-white font-bold text-[17px] w-[160px] h-[45px] rounded'>
                                <a href='/'>
                                    Start Free
                                </a>
                            </button>
                        </div>
                    }
                </div>
            </header>
        </div>
    )
}
