import React from 'react'

export const Policy = () => {
    return (
        <section className="mt-[135px]">
            <section id="" className=" bg-white px-24 rounded drop-shadow mt-14 sm:max-w-screen-sm md:max-w-[920px] lg:max-w-[1086px] xl:w-[1152px]  p-[14.5px] lg:px-8 mx-auto flex w-full flex-col  items-center justify-center text-center ">
                <h2 className=" md:max-w-2xl mt-3 text-3xl font-bold  text-gray-800 lg:leading-tight lg:text-4xl ">Privacy Policy</h2>
                <div className="w-full md:max-w-2xl py-4 text-lg leading-normal text-gray-800 lg:text-xl xl:text-xl">
                    <p>At  Master Dispatch, we take your privacy seriously and are committed to protecting your personal information. This privacy policy explains what information we collect, how we use it, and how we protect it.</p>
                    <h2 className="text-gray-500 text-lg lg:text-xl xl:text-xl"><b>Information We Collect</b></h2>
                    <ul>
                        <li>When you install and use  Master Dispatch, we may collect certain information such as your email address and browsing history.</li><li>We may also collect information about your use of  Master Dispatch, such as the features you use and the loads you search fo</li>
                    </ul>
                    <h2 className="text-gray-500 text-lg lg:text-xl xl:text-xl"><b>Use of Information</b></h2>
                    <ul>
                        <li>We use the information we collect to provide and improve the  Master Dispatch Chrome extension.</li>
                        <li>We may also use the information to communicate with you, such as to notify you about updates to the extension or to send you promotional material</li>
                    </ul>
                    <h2 className="text-gray-500 text-lg lg:text-xl xl:text-xl"><b>Sharing of Information</b></h2>
                    <ul>
                        <li>We will not share your personal information with third parties for marketing or advertising purposes.</li>
                        <li>We may share your personal information with third parties to provide the  Master Dispatch Chrome extension, such as our servers, or to comply with legal requirements</li>
                    </ul>
                    <h2 className="text-gray-500 text-lg lg:text-xl xl:text-xl"><b>Protection of Information</b></h2>
                    <ul>
                        <li>We take appropriate security measures to protect your personal information against unauthorized access, alteration, or destruction.</li>
                        <li>However, please note that no method of transmission over the Internet, or method of electronic storage, is 100% secure.</li>
                    </ul>
                    <h2 className="text-gray-500 text-lg lg:text-xl xl:text-xl"><b>Emailing grant</b></h2>
                    <ul>
                        <p>Regarding the use of the "gmail.send" for GMAIL and "Mail.Send" for OUTLOOK scopes for sending emails from users' accounts, we assure you of the following:<br/>We will only request access to this scope when necessary for the functionality of our app. We will not send any emails without the explicit action of the user.<br/>We will not store or share any user's email address or other personal information without their explicit consent.<br/>We will not use any emails sent through our app for any marketing or advertising purposes.<br/>We will not share any data collected through the use of this scope with any third parties.<br/>Users have the right to revoke our access to this scope at any time through their Google or Outlook Account Settings..<br/>By using our app, users consent to the collection and use of their information as described in this privacy policy.</p>
                    </ul><br/>
                    <h2 className="text-gray-500 text-lg lg:text-xl xl:text-xl"><b>Changes to This Policy</b></h2>
                    <p>We may update this privacy policy from time to time to reflect changes in our practices or to comply with legal requirements.</p>
                    <h2 className="text-gray-500 text-lg lg:text-xl xl:text-xl"><b>Contact Us</b></h2>
                    <p>If you have any questions or concerns about our privacy policy, please contact us at Info@dispatchwizard.co</p>
                </div>
            </section>
        </section>
)
}
