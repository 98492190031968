import React from 'react';
import {Header} from "./componennts/Header/Header";
import {Foother} from "./componennts/Foother/Foother";
import {Route,Routes} from "react-router-dom"
import {Main} from "./componennts/Main/Main";
import {Policy} from "./componennts/Policy/Policy";
import {Term} from "./componennts/Term/Term";
import {Contact} from "./componennts/Contact/Contact";
function App() {
  return (
    <div className="w-screen flex flex-col items-center justify-between min-h-screen bg-contain bg-repeat  bg-[url(./acces/vecteezy_line-art-striped-graphic-template-abstract-black-wave-design_11887862_851.png)] ">
      <Header/>
        <main>
            <Routes>
                <Route path={'/'} element={<Main/>}/>
                <Route path={'/policy'} element={<Policy/>}/>
                <Route path={'/term'} element={<Term/>}/>
                <Route path={'/contact'} element={<Contact/>}/>
            </Routes>
        </main>
      <Foother/>
    </div>
  );
}

export default App;
