import React, {useState} from 'react'
import {Slider, Switch} from "antd";

export const Main = () => {
    const [isYearly ,SetYearly] = useState<boolean>(false)
    const [Amount,setAmount] = useState<number>(4)
    return (
        <section className='flex flex-col gap-10 items-center mt-[135px]'>
            <div className='flex lg:flex-row flex-col-reverse gap-8  justify-between items-center mt-3 xl:mt-10 xl:w-[1200px] bg-[#f2f2f2] p-14'>
                <div className='flex gap-5 flex-col text-[19px] font-semibold text-[#333]'>
                    <div className='text-[23px] w-[280px]'>
                        Title titletext texttext tet texttext text texttext text text
                    </div>
                    <div className='w-[350px]'>
                        Email to brokers, calculate RPM, open Google Maps, and report Unreliable brokers with Dispatch Wizard
                    </div>
                    <button className='bg-gradient-to-br bg-indigo-500 to-sky-400 text-white text-bold rounded w-[160px] h-[45px]'>
                        Start Free
                    </button>
                </div>
                <div className='w-[320px] h-[170px] md:w-[600px] md:h-[356px] bg-gradient-to-br bg-indigo-500  to-sky-400 rounded'>

                </div>
            </div>
            <div id='features' className='flex flex-col min-h-[1200px] text-white'>
                <div className=' flex justify-center w-screen bg-gradient-to-r transition-all duration-500 from-indigo-500 to-sky-300 hover:bg-[100vw] min-h-[430px]'>
                    <div className='flex lg:flex-row flex-col-reverse xl:w-[1200px]  justify-between items-center  min-h-[430px] '>
                        <div className='flex flex-col gap-4 text-[19px] p-4 font-semibold w-[340px]'>
                            <div className='text-[23px] font-bold'>
                                Email brokers with a click
                            </div>
                            <div>
                                Create custom templates with dynamic variables and send personalized emails to brokers at scale
                            </div>
                        </div>
                        <div className='w-screen h-[180px] md:w-[540px] md:h-[430px] bg-blue-100 rounded'>

                        </div>
                    </div>
                </div>
                <div className=' flex  justify-center w-screen bg-gradient-to-l transition-all duration-500 from-indigo-500 to-sky-300 hover:bg-[100vw] min-h-[430px]'>
                    <div className='flex lg:flex-row flex-col xl:w-[1200px]  justify-between items-center  min-h-[430px] '>
                        <div className='w-screen h-[180px] md:w-[540px] md:h-[430px] bg-blue-100 rounded'>

                        </div>
                        <div className='flex flex-col gap-4 text-[19px] p-4 font-semibold w-[340px]'>
                            <div className='text-[23px] font-bold'>
                                Calculate RPM automatically
                            </div>
                            <div>
                                Break down the rate per mile for any load and easily filter out the highest paying ones directly on the load board
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' flex  justify-center w-screen bg-gradient-to-r transition-all duration-500 from-indigo-500 to-sky-300 hover:bg-[100vw] min-h-[430px]'>
                    <div className='flex lg:flex-row flex-col-reverse xl:w-[1200px]  justify-between items-center  min-h-[430px] '>
                        <div className='flex flex-col  gap-4 text-[19px] p-4 font-semibold w-[340px]'>
                            <div className='text-[23px] font-bold'>
                                Google Maps with load details
                            </div>
                            <div className='flex flex-col gap-4'>
                                Immediately see the loading route on Google Maps and access all the essential information about the shipment, including:
                                <ul className='list-disc list-inside flex flex-col gap-3'>
                                    <li>
                                        Truck's current location.
                                    </li>
                                    <li>
                                        Pickup point of origin.
                                    </li>
                                    <li>
                                        Delivery destination.
                                    </li>
                                    <li>
                                        Details about the tools or equipment involved.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='w-screen h-[180px] md:w-[540px] md:h-[430px] bg-blue-100 rounded'>

                        </div>
                    </div>
                </div>
                <div className=' flex  justify-center w-screen bg-gradient-to-l transition-all duration-500 from-indigo-500 to-sky-300 hover:bg-[100vw] min-h-[430px]'>
                    <div className='flex lg:flex-row flex-col xl:w-[1200px]  justify-between items-center  min-h-[430px] '>
                        <div className='w-screen h-[180px] md:w-[540px] md:h-[430px] bg-blue-100 rounded'>

                        </div>
                        <div className='flex flex-col gap-4 text-[19px] p-4 font-semibold w-[340px]'>
                            <div className='text-[23px] font-bold'>
                                Report unreliable brokers
                            </div>
                            <div>
                                Read reviews about brokers and share your experiences to protect fellow dispatchers from scammers and untrustworthy companies
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='product' className='w-screen bg-[#f2f2f2] pt-36 pb-6 flex flex-col gap-9 items-center text-[#333]'>
                <div className='text-[32px] lg:text-[48px] font-bold mx-6'>
                    Book loads like a pro with  one click!
                </div>
                <div className='flex flex-wrap w-full justify-center items-center lg:flex-nowrap  text-[19px] font-semibold gap-5'>
                    <div className='flex flex-col w-[250px]'>
                        <div className='flex text-indigo-500 text-[26px] '>
                            10X Faster
                        </div>
                        <div>
                            You can book more loads for your trucks in the first month of use
                        </div>
                    </div>
                    <div className='flex flex-col w-[250px]'>
                        <div className='flex text-indigo-500 text-[26px] '>
                            5000 Users
                        </div>
                        <div>
                            Signed up and been using our extension every day in just a few months
                        </div>
                    </div>
                    <div className='flex flex-col w-[250px]'>
                        <div className='flex text-indigo-500 text-[26px] '>
                            3 minutes
                        </div>
                        <div>
                            To start things off and see how it benefits your trucking business
                        </div>
                    </div>
                </div>
                <div className=' w-[80vw] xl:w-[1200px] mt-14 bg-gradient-to-bl flex-col md:flex-row rounded-2xl from-indigo-500  to-sky-400 font-bold text-[26px] xl:text-[32px] p-12 flex items-center justify-between text-white'>
                    Wanna know how it's works?
                    <button className='bg-white text-[#666] text-[15px] w-[140px] h-[45px] rounded-2xl'>
                        Watch a Tutorial
                    </button>
                </div>
            </div>
            <div id='pricing' className='w-screen bg-[#f2f2f2] p-12 flex flex-col gap-9 items-center text-[#333]'>
                <div className='xl:w-[1200px] flex flex-col items-center '>
                    <div className='text-[26px] my-6 '>
                        Cost-effective pricing.
                    </div>
                    <Switch onChange={(e:boolean)=>SetYearly(e)} className='w-[60px] '/>
                    {
                        isYearly ? <div className='flex justify-evenly flex-wrap gap-14 lg:gap-3 xl:flex-nowrap '>
                            <div className='flex flex-col gap-4 items-center'>
                                <div className='text-[18px] md:text-[21px] font-semibold'>
                                    Basic
                                </div>
                                <div className='flex md:h-[130px]'>

                                    <div className='flex items-center gap-3'>
                                        <div className='text-[21px] md:text-[36px] text-black font-semibold'>$12</div>/per month <br/> billed at $144/yr
                                    </div>
                                </div>
                                <a  href="/" className=' bg-gradient-to-br bg-indigo-500  to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                    Get started
                                </a>
                                <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Unlimited emails
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        1 User Account
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        RPM calculator
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Gmail, Outlook, SMTP
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        1 Email Template
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Customer Support
                                    </li>
                                </ul>
                            </div>
                            <div className='flex flex-col gap-4 items-center'>
                                <div className='text-[18px] md:text-[21px] font-semibold'>
                                    Pro
                                </div>
                                <div className='flex md:h-[130px]'>
                                    <div className='flex items-center gap-3'>
                                        <div className='text-[21px] md:text-[36px] text-black font-semibold'>$28</div>/per month <br/> billed at $336/yr
                                    </div>
                                </div>
                                <a  href="/" className=' bg-gradient-to-br bg-indigo-500  to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                    Get started
                                </a>
                                <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Everything in Starter, plus:
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Unlimited user accounts
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Unlimited Emails
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Load Search Filters
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Google Maps
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Multiple email address
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        DAT, Truckstop, DAT One, Truckersedge
                                    </li>
                                </ul>
                            </div>
                            <div className='flex flex-col gap-4 items-center'>
                                <div className='text-[18px] md:text-[21px] font-semibold'>
                                    Enterprice
                                </div>
                                <div className='flex items-center flex-col '>
                                    <div className='flex items-center gap-3'>
                                        <div className='text-[21px] md:text-[36px] text-black font-semibold'>${10*Amount}</div>/per month <br/> billed at ${10*Amount*12}/yr
                                    </div>
                                    <Slider onChange={(e:number)=>setAmount(e)} min={4} max={20} className='w-[120px]'/>
                                    <div>
                                        User Account:{Amount}
                                    </div>
                                </div>
                                <a  href="/" className='bg-gradient-to-br bg-indigo-500  to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                    Get started
                                </a>

                                <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Everything in Pro, plus:
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>From 4 to Unlimited Users</span>
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>Multiple email addresses</span>
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>Google Maps with Tolls &amp; Load Info</span>
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>Multiple Sending Addresses</span>
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>Email Signature</span>
                                    </li>
                                </ul>
                            </div>
                        </div> : <div className='flex justify-evenly flex-wrap gap-14 lg:gap-3 xl:flex-nowrap '>
                            <div className='flex flex-col gap-4 items-center'>
                                <div className='text-[18px] md:text-[21px] font-semibold'>
                                    Basic
                                </div>
                                <div className='flex md:h-[130px]'>

                                    <div className='flex items-center gap-3'>
                                        <div className='text-[21px] md:text-[36px] text-black font-semibold'>$15</div>/per month
                                    </div>
                                </div>
                                <a  href="/" className=' bg-gradient-to-br bg-indigo-500 to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                    Get started
                                </a>
                                <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Unlimited emails
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        1 User Account
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        RPM calculator
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Gmail, Outlook, SMTP
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        1 Email Template
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Customer Support
                                    </li>
                                </ul>
                            </div>
                            <div className='flex flex-col gap-4 items-center'>
                                <div className='text-[18px] md:text-[21px] font-semibold'>
                                    Pro
                                </div>
                                <div className='flex md:h-[130px]'>
                                    <div className='flex items-center gap-3'>
                                        <div className='text-[21px] md:text-[36px] text-black font-semibold'>$35</div>/per month
                                    </div>
                                </div>
                                <a  href="/" className=' bg-gradient-to-br bg-indigo-500 to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                    Get started
                                </a>
                                <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Everything in Starter, plus:
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Unlimited user accounts
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Unlimited Emails
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Load Search Filters
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Google Maps
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Multiple email address
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        DAT, Truckstop, DAT One, Truckersedge
                                    </li>
                                </ul>
                            </div>
                            <div className='flex flex-col gap-4 items-center'>
                                <div className='text-[18px] md:text-[21px] font-semibold'>
                                    Enterprice
                                </div>
                                <div className='flex items-center flex-col '>
                                    <div className='flex items-center gap-3'>
                                        <div className='text-[21px] md:text-[36px] text-black font-semibold'>${12.5*Amount}</div>/per month
                                    </div>
                                    <Slider onChange={(e:number)=>setAmount(e)} min={4} max={20} className='w-[120px]'/>
                                    <div>
                                        User Account:{Amount}
                                    </div>
                                </div>
                                <a  href="/" className='bg-gradient-to-br bg-indigo-500 to-sky-400 items-center flex justify-center text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                                    Get started
                                </a>
                                <ul className='list-disc pl-10  w-[300px] mx-4 flex flex-col gap-4 text-[18px] font-semibold '>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        Everything in Pro, plus:
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>From 4 to Unlimited Users</span>
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>Multiple email addresses</span>
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>Google Maps with Tolls &amp; Load Info</span>
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>Multiple Sending Addresses</span>
                                    </li>
                                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                                        <span>Email Signature</span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='w-screen bg-[#f2f2f2] p-12 flex flex-col gap-9 items-center text-[#333]'>
                <div className='xl:w-[1200px] flex flex-wrap xl:flex-nowrap gap-6  justify-evenly '>
                    <div className='bg-white drop-shadow w-[320px] border-indigo-500 border border-solid min-h-[250px] flex flex-col gap-7 rounded-md py-8 p-6'>
                        <div className='flex items-center gap-5'>
                            <div className='w-[48px] h-[48px]  bg-white rounded-full'>
                                <img  className='w-[48px] h-[48px]  bg-white rounded-full object-cover' alt=""/>
                            </div>
                            <div className='text-[20px] font-semibold '>
                                Benjamin Foster
                            </div>
                        </div>
                        <div className='max-w-[250px]'>
                            I can't imagine managing our dispatch without Dispatch Buddy. It's a complete game-changer for our team. The user-friendly interface makes our work a breeze.
                        </div>
                    </div>
                    <div className='bg-white drop-shadow w-[320px] border-indigo-500 border border-solid min-h-[250px] flex flex-col gap-7 rounded-md py-8 p-6'>
                        <div className='flex items-center gap-5'>
                            <div className='w-[48px] h-[48px]  bg-white rounded-full'>
                                <img  className='w-[48px] h-[48px]  bg-white rounded-full object-cover' alt=""/>
                            </div>
                            <div className='text-[20px] font-semibold '>
                                Olivia Mitchell
                            </div>
                        </div>
                        <div className='max-w-[250px]'>
                            What I love about Dispatch Buddy is how it streamlines the booking process. It's like having a personal assistant for load management. I've recommended it to all my industry peers!
                        </div>
                    </div>
                    <div className='bg-white drop-shadow w-[320px] border-indigo-500 border border-solid min-h-[250px] flex flex-col gap-7 rounded-md py-8 p-6'>
                        <div className='flex items-center gap-5'>
                            <div className='w-[48px] h-[48px]  bg-white rounded-full'>
                                <img className='w-[48px] h-[48px]  bg-white rounded-full object-cover '  alt=""/>
                            </div>
                            <div className='text-[20px] font-semibold '>
                                Daniel Reynolds
                            </div>
                        </div>
                        <div className='max-w-[250px]'>
                            Dispatch Buddy has made my job significantly easier. I now have more time for the road, and I'm making more money. The best tool in my trucking arsenal.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
